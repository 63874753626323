main h1 {
    color: #0c8472;
    font-size: 48px;
    line-height: 1.1em;
    text-align: left;
    font-weight: 600;
}
main h2 {
    font-size: 26px;
    line-height: 1.5em;
    text-align: left;
    font-weight: 900;
    margin-right: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
}
main h1.title {
    margin-bottom: 0;
}
main h2.title {
    color: #0c8472;
    font-size: 32px;
    line-height: 1.1em;
    text-align: left;
    font-weight: 600;
    margin-top: 0;
}
main section {
    margin-right: 40px;
}
main strong {
    color: #0b6a51;
}
