.app-logo {
}

.app-header {
}

.logo-wrap {
    max-width: 1360px;
    padding: 0 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-family: 'Benne', serif;
}
.logo-wrap img {
    display: block;
    padding: 0;
    margin: 40px 20px 40px 0;
    width: 110px;
    //position: relative;
    //left: -25px;
}

.org {
    color: #0b6a51;
    font-size: 48px;
    line-height: 1em;
    letter-spacing: -1px;
    margin-top: 20px;
}    
.line2 {
    color: #07c0c6;
    font-size: 22px;
    line-height: 1em;
    text-align: right;
    font-weight: 600;
    padding-top: 6px;
    padding-bottom: 20px;
}
.app-header nav {
    display: flex;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 80px;
}
.main-menu {
    background: #727f85;
    background: #6b9b9b;
}
.app-header nav a {
    color: #ffffff;
    display: block;
    padding: 25px 20px 25px 20px;
    margin-right: 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
}
.app-header nav a:first-child {
    padding-left: 0;
}
.app-header nav a:hover {
    text-decoration: underline;
}


.app-header nav a {
    color: #fff;
    display: block;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-right: 20px;
    padding: 25px 20px;
    text-decoration: none;
    text-transform: uppercase;

}
