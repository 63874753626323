.app {
  text-align: center;
}
main {
    position: relative;
    display: flex;
}
main > * {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
main .main-illustration img {
    display: block;
    margin: 0;
    max-width: 100%;
}
main .main-content {
    color: #77848a;
    font-size: 20px;
    line-height: 1.5em;
    padding: 100px 0 0;
    text-align: justify;
}
main .main-content a {
    color: #0c8472;
    font-weight: bold;
    text-decoration: underline;
}
main .main-content > .content-inner {
    position: relative;
    max-width: 680px;
    padding: 0 0 0 80px;
    flex-grow: 1;
}
main .main-content > .content-inner > div {
    padding-right: 40px;
}
main .main-content > .content-inner:after {
    border-right: 10px solid #e2f7f8;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    margin-left: 40px;
}
main .main-content {
    color: #77848a;
    font-size: 16px;
    line-height: 1.6em;
    padding: 100px 0 0;
    text-align: justify;
}
